import React, { useState, useEffect } from "react";
import tw, { styled, css } from "twin.macro";
import { Link } from "gatsby";
import DropdownChevron from "/static/img/svg/down-chevron.svg";
import PropTypes from "prop-types";

const Wrapper = styled.div`
  ${tw`
        text-black
        lg:bg-gray-50
        lg:rounded-xl
        lg:border-2 lg:border-gray-200
    `}
`;

const Columns = styled.div`
  ${tw`lg:grid lg:grid-cols-2`}
`;

const ColHeader = styled.div`
  ${tw` 
        flex gap-5
        lg:pb-4 lg:pt-0
        lg:w-full lg:items-center lg:justify-between lg:no-underline
        lg:whitespace-pre-line
        hover:cursor-default
    `}

  h6, h5 {
    ${tw`font-semibold inline-block`}
  }

  @media (max-width: 1024px) {
    ${tw`
        py-2 pl-8 pr-5
        hover:bg-gray-200
    `}

    h6 {
      ${tw`text-xl`}
    }
  }
`;

const ColHeaderLink = styled(Link)`
  ${tw`whitespace-nowrap`}

  &:first-of-type {
    ${tw`hover:underline`}

    h6, h5 {
      ${({ to }) => to && tw`hover:underline`}
    }

    @media (max-width: 1024px) {
      ${({ mobileEnabled }) =>
        mobileEnabled
          ? css`
              color: ${(props) => props.textColor || "darkorange"};

              > h5,
              h6 {
                ${tw`font-normal`}
              }
            `
          : tw`pointer-events-none cursor-default`}
    }
  }

  &:nth-of-type(2) {
    ${tw`
        hidden 
        lg:block
        underline
    `}

    &:hover {
      text-decoration-color: ${(props) => props.hoverColor || "orange"};
    }
  }
`;

const Column = styled.div`
  ${tw`lg:p-8 lg:border-gray-200`}

  ${({ idx }) => idx > 1 && tw`lg:border-t-2`}
  ${({ idx }) => idx % 2 === 1 && tw`lg:border-l-2`}

  ${({ contentCount, idx }) =>
    contentCount % 2 === 1 && idx === contentCount - 1 && tw`lg:col-span-full`}

  ${({ fullWidthCol }) =>
    fullWidthCol &&
    css`
      ${tw`lg:col-span-full`}

      &:first-child {
        ${tw`
            hidden
            lg:block 
            lg:border-b-2
        `}

        > ${ColHeader} {
          ${tw`w-full`}
        }
      }

      &:last-child {
        ${tw`
            lg:border-t-2
            rounded-b-xl
        `}
      }

      > ${ColHeader} {
        ${tw`lg:pb-0`}
      }
    `}

    ${({ bgImage }) =>
    bgImage &&
    css`
      @media (min-width: 1024px) {
        background-image: ${bgImage};
      }

      ${tw`
            lg:bg-cover
            lg:text-white
        `}
    `}

    ${({ onlyMobile }) => onlyMobile && tw`lg:hidden`}

  @media (max-width: 1024px) {
    ${tw`border-b-2 border-dotted`}

    &:last-child {
      ${tw`border-none`}
    }
  }
`;

const ColBody = styled.div`
  > p {
    ${tw`
        hidden pb-4
        lg:block
    `}
  }
`;

const ColBodyList = styled.ul`
  ${tw`
        list-none
        lg:grid lg:gap-4
    `}

  ${({ singleCol }) => (singleCol ? tw`lg:grid-cols-1` : tw`lg:grid-cols-2`)}

  @media only screen and (max-width: 1024px) {
    ${tw`
        transition-all duration-300
        static opacity-100 top-16 visible 
        origin-top transform scale-y-0 
        max-h-0
    `}

    ${({ open }) =>
      open &&
      tw`     
        transform scale-y-100
        max-h-full
        `}
  }
`;

const ColBodyListItem = styled.li`
  ${tw`flex items-center lg:items-start`}

  ${({ onlyMobile }) => onlyMobile && tw`lg:hidden`}

  @media (max-width: 1024px) {
    ${tw`
        pl-11 py-3.5 pr-5
        hover:bg-gray-200
    `}
  }
`;

const ColBodyLink = styled(Link)`
  ${tw`
        flex items-start 
        lg:text-lg lg:font-normal
        hover:underline hover:cursor-pointer
    `}

  > img {
    ${tw`h-6 w-6 mr-1.5 mt-0.5`}
  }

  > span {
    ${tw`text-xl lg:text-lg`}
  }

  @media (max-width: 1024px) {
    color: ${(props) => props.textColor || "darkorange"};
  }
`;

const BottomPaneBody = styled.p`
  ${tw`
        hidden lg:inline-block
        text-lg lg:max-w-xl
        mx-auto pl-2.5
    `}
`;

const BottomPaneButton = styled(Link)`
  ${tw`
        hidden lg:block
        rounded-xl
        text-white text-center whitespace-nowrap
        p-4
        transition-colors
    `}

  ${({ bgColor }) =>
    bgColor &&
    css`
      background-color: ${bgColor};

      :hover {
        color: ${bgColor};
        background-color: white;
      }
    `}
`;

const Chevron = styled(DropdownChevron)`
  ${tw`
        inline-block h-auto
        ml-auto w-5
        lg:hidden
    `}

  ${({ rightArrow }) =>
    rightArrow &&
    css`
      path {
        fill: ${(props) => props.fillColor || "darkorange"};
      }

      ${tw`transform -rotate-90`}
    `}
`;

const NavDropdownMenuContent = ({ hoveredLink }) => {
  const [mql, setMql] = useState();
  const [dropdownStates, setDropdownStates] = useState(() => {
    if (hoveredLink) {
      return new Array(NAV_DATA[hoveredLink].content.length).fill(false);
    } else return [];
  });
  const [hoveredNavItem, setHoveredNavItem] = useState(NAV_DATA[hoveredLink]);

  useEffect(() => {
    setMql(window.matchMedia("(max-width: 1024px)"));
  }, []);

  const toggleDropdown = (idx) => () => {
    if (mql.matches) {
      let newState = [...dropdownStates];
      newState[idx] = !newState[idx];
      setDropdownStates(newState);
    }
  };

  return (
    <>
      {hoveredLink && (
        <Wrapper isSingleCol={hoveredNavItem.content.length === 1}>
          <Columns>
            {hoveredNavItem && (
              <>
                {hoveredNavItem.header && (
                  <Column fullWidthCol tw="lg:py-4">
                    <ColHeader>
                      <ColHeaderLink to={hoveredNavItem.header.to}>
                        <h6>{hoveredNavItem.header.label}</h6>
                      </ColHeaderLink>
                      {hoveredNavItem.header.to && (
                        <ColHeaderLink
                          to={hoveredNavItem.header.to}
                          hoverColor={hoveredNavItem.linkHoverColor}
                        >
                          Learn More &#8594;
                        </ColHeaderLink>
                      )}
                    </ColHeader>
                  </Column>
                )}
                {hoveredNavItem.header?.to && (
                  <Column onlyMobile>
                    <ColHeader>
                      <ColHeaderLink
                        to={hoveredNavItem.header.to}
                        textColor={hoveredNavItem.mobileLinksColor}
                        mobileEnabled
                      >
                        <h6>More Info</h6>
                      </ColHeaderLink>
                      <Chevron
                        fillColor={hoveredNavItem.mobileLinksColor}
                        rightArrow
                      />
                    </ColHeader>
                  </Column>
                )}
                {hoveredNavItem.content.map((col, colIdx) => (
                  <Column
                    contentCount={hoveredNavItem.content.length}
                    idx={colIdx}
                    key={colIdx}
                  >
                    <ColHeader onClick={toggleDropdown(colIdx)}>
                      <ColHeaderLink to={col.title.to}>
                        <h6>{col.title.label}</h6>
                      </ColHeaderLink>
                      {col.title.to && (
                        <ColHeaderLink
                          to={col.title.to}
                          hoverColor={hoveredNavItem.linkHoverColor}
                        >
                          Learn More &#8594;
                        </ColHeaderLink>
                      )}
                      <Chevron />
                    </ColHeader>
                    <ColBody>
                      {col.description && <p>{col.description}</p>}
                      <ColBodyList
                        open={dropdownStates[colIdx]}
                        singleCol={hoveredNavItem.singleCol}
                      >
                        {col.title.to && (
                          <ColBodyListItem onlyMobile>
                            <ColBodyLink
                              to={col.title.to}
                              textColor={hoveredNavItem.mobileLinksColor}
                            >
                              <span>More Info</span>
                            </ColBodyLink>
                            <Chevron
                              fillColor={hoveredNavItem.mobileLinksColor}
                              rightArrow
                            />
                          </ColBodyListItem>
                        )}
                        {col.links.map((link, linkIdx) => (
                          <ColBodyListItem key={linkIdx}>
                            <ColBodyLink
                              to={link.to}
                              textColor={hoveredNavItem.mobileLinksColor}
                            >
                              <img src={link.iconPath} alt="icon" />
                              <span>{link.label}</span>
                            </ColBodyLink>
                            <Chevron
                              fillColor={hoveredNavItem.mobileLinksColor}
                              rightArrow
                            />
                          </ColBodyListItem>
                        ))}
                      </ColBodyList>
                    </ColBody>
                  </Column>
                ))}
              </>
            )}
            {hoveredNavItem.bottomPane && (
              <Column
                fullWidthCol
                tw="lg:py-4"
                bgImage={hoveredNavItem.bottomPane.bgImage}
              >
                <ColHeader>
                  <ColHeaderLink
                    to={hoveredNavItem.bottomPane.link.to}
                    textColor={hoveredNavItem.mobileLinksColor}
                    mobileEnabled
                  >
                    <h6>{hoveredNavItem.bottomPane.title}</h6>
                  </ColHeaderLink>
                  <Chevron
                    fillColor={hoveredNavItem.mobileLinksColor}
                    rightArrow
                  />
                  <BottomPaneBody>
                    {hoveredNavItem.bottomPane.body}
                  </BottomPaneBody>
                  <BottomPaneButton
                    to={hoveredNavItem.bottomPane.link.to}
                    bgColor={hoveredNavItem.bottomPane.buttonColor}
                  >
                    {hoveredNavItem.bottomPane.link.label}
                  </BottomPaneButton>
                </ColHeader>
              </Column>
            )}
          </Columns>
        </Wrapper>
      )}
    </>
  );
};

NavDropdownMenuContent.propTypes = {
  hoveredLink: PropTypes.string.isRequired,
};

export default NavDropdownMenuContent;

const NAV_DATA = {
  build: {
    linkHoverColor: "orange",
    mobileLinksColor: "darkorange",
    header: {
      label: "Build with LT3",
      to: "/build",
    },
    bottomPane: {
      title: "Are You Mobile?",
      body: "We offer a low-cost, turnkey software package for mobile, on location, or online businesses. iOS + Android apps included.",
      link: {
        label: "Learn more",
        to: "/lt3-logistics-engine",
      },
      bgImage: "url(/img/svg/nav/bottom-pane/bottom-pane-1.svg)",
      buttonColor: "#e65a00",
    },
    content: [
      {
        title: {
          label: "Software Services",
          to: "/build",
        },
        description:
          "Need help?  Whether it's an app, custom software, or general consulting, we can help with all your technology needs.",
        links: [
          {
            label: "App Development",
            to: "/build/app-development",
            iconPath: "/img/svg/nav/app-development.svg",
          },
          {
            label: "Software Dev",
            to: "/build/software-dev",
            iconPath: "/img/svg/nav/software-development.svg",
          },
          {
            label: "DEVOPS",
            to: "/build/devops",
            iconPath: "/img/svg/nav/devops.svg",
          },
          {
            label: "Cloud Computing",
            to: "/build/cloud-computing",
            iconPath: "/img/svg/nav/cloud-computing.svg",
          },
          {
            label: "Network & Security",
            to: "/build/network-and-security",
            iconPath: "/img/svg/nav/network-security.svg",
          },
          {
            label: "Consulting",
            to: "/build/consulting",
            iconPath: "/img/svg/nav/consulting.svg",
          },
        ],
      },
      {
        title: {
          label: "ATG Services",
          to: "/build/advanced-technology-group",
        },
        links: [
          {
            label: "Edge Computing",
            to: "/build/edge-computing",
            iconPath: "/img/svg/nav/edge-computing.svg",
          },
          {
            label: "Artificial Intelligence",
            to: "/build/artificial-intelligence",
            iconPath: "/img/svg/nav/artificial-intelligence.svg",
          },
          {
            label: "Machine Learning",
            to: "/build/machine-learning",
            iconPath: "/img/svg/nav/machine-learning.svg",
          },
          {
            label: "Neural Networks",
            to: "/build/neural-networks",
            iconPath: "/img/svg/nav/neural-networks.svg",
          },
          {
            label: "Cryptography",
            to: "/build/cryptography",
            iconPath: "/img/svg/nav/cryptography.svg",
          },
          {
            label: "Blockchain",
            to: "/build/blockchain",
            iconPath: "/img/svg/nav/blockchain.svg",
          },
          {
            label: "Virtual Reality",
            to: "/build/virtual-reality",
            iconPath: "/img/svg/nav/virtual-reality.svg",
          },
          {
            label: "Augmented Reality",
            to: "/build/augmented-reality",
            iconPath: "/img/svg/nav/augmented-reality.svg",
          },
          {
            label: "Computer Vision",
            to: "/build/computer-vision",
            iconPath: "/img/svg/nav/computer-vision.svg",
          },
          {
            label: "Internet of Things",
            to: "/build/internet-of-things",
            iconPath: "/img/svg/nav/internet-of-things.svg",
          },
        ],
      },
    ],
  },
  sell: {
    linkHoverColor: "lightsteelblue",
    mobileLinksColor: "steelblue",
    header: {
      label: "Sell with LT3",
      to: "/sell",
    },
    bottomPane: {
      title: "Need a Website?",
      body: "Build your sales funnels using the fastest, SEO-friendly technology so your customers can easily find your business.",
      link: {
        label: "Learn  more",
        to: "/sell/web-development",
      },
      bgImage: "url(/img/svg/nav/bottom-pane/bottom-pane-2.svg)",
      buttonColor: "#2b506e",
    },
    content: [
      {
        title: {
          label: "Digital Media",
          to: "/sell/digital-media",
        },
        links: [
          {
            label: "UI / UX",
            to: "/sell/ui-ux",
            iconPath: "/img/svg/nav/ui-ux.svg",
          },
          {
            label: "Brand Development",
            to: "/sell/brand-development",
            iconPath: "/img/svg/nav/brand-development.svg",
          },
          {
            label: "Web Development",
            to: "/sell/web-development",
            iconPath: "/img/svg/nav/web-development-blue.svg",
          },
          {
            label: "Graphics Design",
            to: "/sell/graphics-design",
            iconPath: "/img/svg/nav/graphics-design.svg",
          },
        ],
      },
      {
        title: {
          label: "Digital Marketing",
          to: "/sell/digital-marketing",
        },
        links: [
          {
            label: "Digital Advertising",
            to: "/sell/digital-advertising",
            iconPath: "/img/svg/nav/digital-advertising-blue.svg",
          },
          {
            label: "Ad Creation",
            to: "/sell/ad-creation",
            iconPath: "/img/svg/nav/ad-creation.svg",
          },
          {
            label: "SEO",
            to: "/sell/seo",
            iconPath: "/img/svg/nav/search-engine-optimization.svg",
          },
          {
            label: "Social Media",
            to: "/sell/social-media",
            iconPath: "/img/svg/nav/social-media-management-blue.svg",
          },
          {
            label: "Public Relations",
            to: "/sell/public-relations",
            iconPath: "/img/svg/nav/media-public-relations.svg",
          },
        ],
      },
    ],
  },
  pricing: {
    linkHoverColor: "orange",
    mobileLinksColor: "darkorange",
    header: {
      label: "Pricing & Packages",
      to: "/pricing",
    },
    singleCol: true,
    content: [
      {
        title: {
          label: "Packages",
        },
        links: [
          {
            label: "App Development",
            to: "/pricing/app-development",
            iconPath: "/img/svg/nav/app-development.svg",
          },
          {
            label: "Website Development",
            to: "/pricing/website-development",
            iconPath: "/img/svg/nav/web-development-orange.svg",
          },
          {
            label: "Digital Marketing",
            to: "/pricing/digital-marketing",
            iconPath: "/img/svg/nav/digital-advertising-orange.svg",
          },
          {
            label: "Social Media Management",
            to: "/pricing/social-media-management",
            iconPath: "/img/svg/nav/social-media-management-orange.svg",
          },
        ],
      },
      {
        title: {
          label: "Consulting",
        },
        links: [
          {
            label: "Container Deposit Systems",
            to: "/pricing/container-deposit-systems",
            iconPath: "/img/svg/nav/cds.svg",
          },
          {
            label: "Extended Producer Responsibility",
            to: "/pricing/extended-producer-responsibility",
            iconPath: "/img/svg/nav/epr.svg",
          },
          {
            label: "Software Consulting",
            to: "/pricing/software-consulting",
            iconPath: "/img/svg/nav/software-consulting.svg",
          },
        ],
      },
      {
        title: {
          label: "Solutions",
        },
        links: [
          {
            label: "Beverage Container Image Processing",
            to: "/pricing/beverage-container-image-processing",
            iconPath: "/img/svg/nav/bcip.svg",
          },
          {
            label: "Commodity Tracking & Collection",
            to: "/pricing/commodity-tracking",
            iconPath: "/img/svg/nav/commodity-tracking.svg",
          },
        ],
      },
      {
        title: {
          label: "Integrations",
        },
        links: [
          {
            label: "ChatGPT / LLM",
            to: "/pricing/chatgpt-integration",
            iconPath: "/img/svg/nav/chatbot.svg",
          },
          {
            label: "Image Recognition",
            to: "/pricing/image-recognition-integration",
            iconPath: "/img/svg/nav/computer-vision.svg",
          },
        ],
      },
    ],
  },
  ourWork: {
    linkHoverColor: "darkgrey",
    mobileLinksColor: "dimgrey",
    header: {
      label: "Our Work at LT3",
    },
    bottomPane: {
      title: "Bottle Bill?",
      body: "We have been consulting and building for container-deposit systems for over a decade.",
      link: {
        label: "Learn more",
        to: "/container-deposit-scheme",
      },
      bgImage: "url(/img/svg/nav/bottom-pane/bottom-pane-3.svg)",
      buttonColor: "#4d4d4d",
    },
    content: [
      {
        title: {
          label: "Key Industries",
          to: "/key-industries",
        },
        links: [
          {
            label: "Environmental",
            to: "/key-industries/environmental",
            iconPath: "/img/svg/nav/recycling-environmental.svg",
          },
          {
            label: "Oil & Gas",
            to: "/key-industries/oil-and-gas",
            iconPath: "/img/svg/nav/oil-gas.svg",
          },
          {
            label: "Healthcare",
            to: "/key-industries/healthcare",
            iconPath: "/img/svg/nav/healthcare.svg",
          },
          {
            label: "Logistics",
            to: "/key-industries/logistics",
            iconPath: "/img/svg/nav/transportation-logistics.svg",
          },
          {
            label: "Start Ups",
            to: "/key-industries/start-ups",
            iconPath: "/img/svg/nav/startups.svg",
          },
        ],
      },
      {
        title: {
          label: "Projects",
          to: "/projects",
        },
        links: [
          {
            label: "Express",
            to: "/projects/express",
            iconPath: "/img/svg/nav/express-logo.svg",
          },
          {
            label: "Chain of Custody",
            to: "/projects/chain-of-custody",
            iconPath: "/img/svg/nav/coc-logo.svg",
          },
          {
            label: "SkipTheDepot",
            to: "/projects/skipthedepot",
            iconPath: "/img/svg/nav/skipthedepot-logo.svg",
          },
          {
            label: "Trotter & Morton",
            to: "/projects/trotter-and-morton",
            iconPath: "/img/svg/nav/tmgroup-logo.svg",
          },
          {
            label: "Ventrek",
            to: "/projects/ventrek",
            iconPath: "/img/svg/nav/ventrek-logo.svg",
          },
          {
            label: "Ichor",
            to: "/projects/ichor",
            iconPath: "/img/svg/nav/ichor-logo.svg",
          },
          {
            label: "ReCollect",
            to: "/projects/recollect",
            iconPath: "/img/svg/nav/recollect-logo.svg",
          },
        ],
      },
    ],
  },
  community: {
    linkHoverColor: "orange",
    mobileLinksColor: "darkorange",
    content: [
      {
        title: {
          label: "LT3 Community",
        },
        description: "See what we're up to and how to get in touch.",
        links: [
          {
            label: "Media",
            to: "/media",
            iconPath: "/img/svg/nav/media.svg",
          },
          {
            label: "Awards",
            to: "/awards",
            iconPath: "/img/svg/nav/awards.svg",
          },
          {
            label: "Blog",
            to: "/blog",
            iconPath: "/img/svg/nav/blog.svg",
          },
        ],
      },
    ],
  },
};
