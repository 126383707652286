import React from "react"
import tw, { styled, css } from 'twin.macro'

const Container = styled.section`
  ${tw`
    grid
    mb-36
  `}

  background-color: ${props => props.bgColor || "transparent"};
  grid-template-columns: [full-start] minmax(2rem,1fr) [standard-start] 3.75rem [narrow-start] minmax(1rem,67.5rem) [narrow-end] 3.75rem [standard-end] minmax(2rem,1fr) [full-end];

  > * {
    ${tw`
      mx-auto
    `}

    grid-column: standard;

    ${({ fullWidth }) => fullWidth && css`
      ${tw`
        max-w-screen-2xl
        px-6 md:px-12
      `}

      grid-column: full;
    `}
  }
`

const Section = ({ fullWidth, bgColor, children, className }) => (
  <Container bgColor={bgColor} fullWidth={fullWidth} className={className}>
    {children}
  </Container>
)

export default Section