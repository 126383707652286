import React from "react";
import GlobalStyles from "../styles/GlobalStyles";
import Nav from "./Nav";
import Footer from "./Footer";
import Seo from "./Seo";

const Layout = ({ title, description, children, isHomePage, hideContact }) => (
  <>
    <GlobalStyles />
    <Seo title={title} description={description} isHomePage={isHomePage} />
    <Nav hideContact={hideContact} />
    {children}
    <Footer />
  </>
);

export default Layout;
