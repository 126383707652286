import React, { useState, useEffect, useRef, useContext } from "react";
import tw, { styled, css } from "twin.macro";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { motion } from "framer-motion";

import { EmailContext } from "../contexts/EmailContext";

import DropdownChevron from "/static/img/svg/down-chevron.svg";
import NavDropdownMenuContent from "./NavDropdownMenuContent";

const Wrapper = styled(motion.header)`
  ${tw`
      sticky top-0 
      flex bg-white
      w-full p-3.5 
      lg:text-black
  `}

  z-index: 101;
  transition: background-color 0.5s ease-in-out, box-shadow 0.5s ease-in-out,
    backdrop-filter 0.5s ease-in-out;

  ${({ isBlurBg }) =>
    isBlurBg &&
    tw`
        backdrop-filter backdrop-blur saturate-100
        bg-opacity-80
        shadow
    `}

  @media (max-width: 1024px) {
    ${({ dropdownOpen }) =>
      dropdownOpen &&
      tw`
          bg-white
          overflow-y-scroll             
      `}
  }
`;

const Container = styled.div`
  ${tw`
      p-0 w-full
      lg:grid lg:w-auto lg:mx-auto
  `}

  grid-template-columns: [full-start] 2rem [standard-start] 3.75rem [narrow-start] minmax(1rem,67.5rem) [narrow-end] 3.75rem [standard-end] 2rem [full-end];
`;

const Content = styled.div`
  ${tw`
      py-2 rounded-lg items-center
      lg:flex 
  `}

  grid-column: standard;
`;

const LogoLinkContainer = styled.div`
  ${tw`
      ml-8
      lg:mr-4 lg:ml-0 w-min 
  `}
`;

const Checkbox = styled.input`
  ${tw`hidden`}
`;

const NavContainer = styled.div`
  ${tw`
      w-full 
      h-0 lg:h-auto
  `}

  ${({ dropdownOpen }) => dropdownOpen && tw`h-auto`}
`;

const MenuIcon = styled.label`
  ${tw`
      absolute mt-0.5 top-10 right-14
      block lg:hidden
      translate-x-6 px-6 
      -translate-y-4 py-4
  `}

  z-index: 103;

  span {
    ${tw`
        absolute bg-black 
        transition-all duration-200
        transform -translate-x-1/2 -translate-y-1/2 
    `}

    width: 3rem;
    height: 4.5px;

    ${Checkbox}:checked ~ & {
      ${tw`bg-transparent`}
    }

    &::before,
    &::after {
      ${tw`
          absolute bg-black 
          transition-all duration-200
      `}

      content: "";
      width: 3rem;
      height: 4.5px;
    }

    &::before {
      ${tw`top-3`}

      ${Checkbox}:checked ~ & {
        ${tw`
            transform rotate-45 
            top-0 bg-black
        `}
      }
    }

    &::after {
      ${tw`-top-3`}

      ${Checkbox}:checked ~ & {
        ${tw`
            transform -rotate-45 
            top-0 bg-black
        `}
      }
    }
  }
`;

const Links = styled.nav`
  ${tw`
      lg:flex lg:items-center lg:justify-between lg:h-auto 
      // lg:-mt-2     //uncomment to offset the link text slightly higher
  `}

  @media (max-width: 1024px) {
    ${tw`
        opacity-0 leading-10
        // transition-all duration-300      //uncomment to enable close/collapse animation for main links on smaller screens
        origin-top transform scale-y-0
        max-h-0 w-full
        p-3
    `}

    z-index: 100;

    ${Checkbox}:checked ~ & {
      ${tw` 
          transition-all duration-300
          opacity-100 
          transform scale-y-100
          max-h-full
      `}
    }
  }
`;

const NavList = styled.ul`
  ${tw`
      flex flex-col list-none h-full
      lg:flex-row lg:w-full lg:h-auto lg:flex-nowrap
  `}
`;

const NavListItem = styled.li`
  ${tw`flex text-left`}

  ${({ isSpacer }) =>
    isSpacer &&
    tw`
        flex-grow 
        hidden lg:block
    `}

    @media (max-width: 1024px) {
    ${tw`relative`}
  }
`;

const NavLinkOuter = styled.div`
  ${tw`
      lg:py-1 lg:px-2.5
      w-full lg:w-auto
      border-b-2 border-gray-300 lg:border-none
  `}
`;

const NavLinkInner = styled.div`
  ${tw`
      p-3 flex items-center no-underline
      lg:px-0 lg:py-0
  `}

  @media only screen and (max-width: 1024px) {
    ${tw`
        px-5
        hover:bg-gray-200
    `}
  }
`;

const NavLink = styled(Link)`
  ${tw`
      flex items-center whitespace-nowrap
      text-xl font-bold 
      lg:text-base lg:py-1.5 lg:px-0
  `}

  cursor: ${(props) => (props.to ? "pointer" : "default")};
  :hover {
    text-decoration: ${(props) => (props.to ? "underline" : "none")};
  }

  @media (min-width: 1024px) {
    &:hover {
      color: darkorange;
    }
  }

  @media (max-width: 1024px) {
    ${({ mobileEnabled }) =>
      mobileEnabled
        ? css`
            text-decoration-color: darkorange !important;

            span {
              color: darkorange;

              ${tw`font-normal`}
            }
          `
        : tw`pointer-events-none cursor-default`}
  }
`;

const DropdownMenuContainer = styled.div`
  ${tw` 
      absolute overflow-hidden
      filter lg:drop-shadow-2xl
      transform
      lg:invisible lg:opacity-0 
      lg:left-1/2  lg:-translate-x-1/2
      lg:max-w-5xl
  `}

  z-index: 102;

  @media (min-width: 1024px) {
    width: max-content;

    &:hover,
    ${NavLinkOuter} > ${NavLinkInner}:hover + & {
      ${tw`
          transition-opacity duration-300
          visible opacity-100
      `}
    }
  }

  @media only screen and (max-width: 1024px) {
    ${tw`
        transition-all duration-300
        w-full
        overflow-hidden 
        static opacity-100 top-16 visible 
        origin-top transform scale-y-0
        max-h-0
    `}

    ${({ open }) =>
      open &&
      tw`
          transform scale-y-100
          max-h-full
      `}
  }
`;

const Chevron = styled(DropdownChevron)`
  ${tw`
      inline-block h-auto
      ml-auto w-5
      lg:ml-0.5 lg:w-2.5
  `}

  ${({ rightArrow }) =>
    rightArrow &&
    css`
      path {
        fill: darkorange;
      }

      ${tw`
          lg:hidden
          transform -rotate-90
      `}
    `}
`;

const Nav = ({ hideContact }) => {
  const { setShowEmail, setType } = useContext(EmailContext);

  const [mql, setMql] = useState();
  const [isChecked, setIsChecked] = useState(false);
  const [isBlurBg, setIsBlurBg] = useState(false);
  const [dropdownStates, setDropdownStates] = useState({
    build: false,
    sell: false,
    pricing: false,
    ourWork: false,
    community: false,
  });
  const menuRef = useRef();
  const navRef = useRef();

  useEffect(() => {
    function handleMqlTrigger(e) {
      if (!e.matches) {
        // The window is now over 1024px
        document.body.classList.remove("hide-vertical-scroll");
        menuRef.current.checked = false;
        setIsChecked(false);
      }
    }

    let mediaQueryList = window.matchMedia("(max-width: 1024px)");
    mediaQueryList.addEventListener("change", handleMqlTrigger);
    setMql(mediaQueryList);

    document.addEventListener("scroll", handleScroll);
    handleScroll();

    return () => {
      mediaQueryList.removeEventListener("change", handleMqlTrigger);
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleScroll() {
    if (window.scrollY === 0) {
      setIsBlurBg(false);
    } else {
      setIsBlurBg(true);
    }
  }

  const toggleDropdown = (key) => () => {
    if (mql.matches) {
      let newState = { ...dropdownStates };
      newState[key] = !newState[key];
      setDropdownStates(newState);
    }
  };

  function handleCheck(e) {
    if (e.target.checked) {
      document.body.classList.add("hide-vertical-scroll");
    } else {
      document.body.classList.remove("hide-vertical-scroll");
    }

    setIsChecked((prevIsChecked) => !prevIsChecked);
  }

  return (
    <Wrapper
      // initial={{height: "auto"}}
      animate={{ height: isChecked ? "100vh" : "auto" }}
      transition={{ duration: 0.3, type: "tween" }}
      dropdownOpen={isChecked}
      isBlurBg={isBlurBg}
      ref={navRef}
    >
      <Container>
        <Content>
          <LogoLinkContainer>
            <Link to="/">
              <StaticImage
                src="../../static/img/lt3_logo_flat.png"
                alt="LessThan3 Logo"
                placeholder="tracedSVG"
                layout="fixed"
                height={40} //hard-setting the width causes minor cutoff at the end of logo
              />
            </Link>
          </LogoLinkContainer>
          <NavContainer dropdownOpen={isChecked}>
            <Checkbox
              type="checkbox"
              id="menu"
              ref={menuRef}
              onChange={handleCheck}
            />
            <MenuIcon htmlFor="menu">
              <span />
            </MenuIcon>
            <Links>
              <NavList>
                <NavListItem>
                  <NavLinkOuter>
                    <NavLinkInner>
                      <NavLink to="/about-lt3" mobileEnabled>
                        <span>About LT3</span>
                      </NavLink>
                      <Chevron rightArrow />
                    </NavLinkInner>
                  </NavLinkOuter>
                </NavListItem>
                <NavListItem>
                  <NavLinkOuter>
                    <NavLinkInner onClick={toggleDropdown("build")}>
                      <NavLink to="/build">
                        <span>Build</span>
                      </NavLink>
                      <Chevron />
                    </NavLinkInner>
                    <DropdownMenuContainer open={dropdownStates["build"]}>
                      <NavDropdownMenuContent hoveredLink="build" />
                    </DropdownMenuContainer>
                  </NavLinkOuter>
                </NavListItem>
                <NavListItem>
                  <NavLinkOuter>
                    <NavLinkInner onClick={toggleDropdown("sell")}>
                      <NavLink to="/sell">
                        <span>Sell</span>
                      </NavLink>
                      <Chevron />
                    </NavLinkInner>
                    <DropdownMenuContainer open={dropdownStates["sell"]}>
                      <NavDropdownMenuContent hoveredLink="sell" />
                    </DropdownMenuContainer>
                  </NavLinkOuter>
                </NavListItem>
                <NavListItem>
                  <NavLinkOuter>
                    <NavLinkInner onClick={toggleDropdown("pricing")}>
                      <NavLink to="/pricing">
                        <span>Pricing</span>
                      </NavLink>
                      <Chevron />
                    </NavLinkInner>
                    <DropdownMenuContainer open={dropdownStates["pricing"]}>
                      <NavDropdownMenuContent hoveredLink="pricing" />
                    </DropdownMenuContainer>
                  </NavLinkOuter>
                </NavListItem>
                <NavListItem>
                  <NavLinkOuter>
                    <NavLinkInner onClick={toggleDropdown("ourWork")}>
                      <NavLink>
                        <span>Our Work</span>
                      </NavLink>
                      <Chevron />
                    </NavLinkInner>
                    <DropdownMenuContainer open={dropdownStates["ourWork"]}>
                      <NavDropdownMenuContent hoveredLink="ourWork" />
                    </DropdownMenuContainer>
                  </NavLinkOuter>
                </NavListItem>
                <NavListItem>
                  <NavLinkOuter>
                    <NavLinkInner onClick={toggleDropdown("community")}>
                      <NavLink>
                        <span>Community</span>
                      </NavLink>
                      <Chevron />
                    </NavLinkInner>
                    <DropdownMenuContainer open={dropdownStates["community"]}>
                      <NavDropdownMenuContent hoveredLink="community" />
                    </DropdownMenuContainer>
                  </NavLinkOuter>
                </NavListItem>
                <NavListItem isSpacer />
                <NavListItem>
                  <NavLinkOuter>
                    <NavLinkInner>
                      <NavLink to="/careers" mobileEnabled>
                        <span>Careers</span>
                      </NavLink>
                      <Chevron rightArrow />
                    </NavLinkInner>
                  </NavLinkOuter>
                </NavListItem>
                {!hideContact && (
                  <NavListItem>
                    <NavLinkOuter>
                      <NavLinkInner>
                        <NavLink mobileEnabled>
                          <button
                            tw="font-bold"
                            onClick={() => {
                              setShowEmail(true);
                              setType("general");
                            }}
                          >
                            <span>Contact</span>
                          </button>
                        </NavLink>
                        <Chevron rightArrow />
                      </NavLinkInner>
                    </NavLinkOuter>
                  </NavListItem>
                )}
              </NavList>
            </Links>
          </NavContainer>
        </Content>
      </Container>
    </Wrapper>
  );
};

export default Nav;
