import React, { useEffect, useState } from "react";
import tw, { styled, css } from "twin.macro";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import DropdownChevron from "/static/img/svg/down-chevron.svg";

const Wrapper = styled.div`
  ${tw`grid`}

  grid-template-columns: [full-start] minmax(2rem,1fr) [standard-start] 3.75rem [narrow-start] minmax(1rem,67.5rem) [narrow-end] 3.75rem [standard-end] minmax(2rem,1fr) [full-end];
`;

const Content = styled.div`
  ${tw`
        w-full
        mx-auto mt-24
        flex flex-col 
        relative
        text-sm
    `}

  grid-column: standard;
`;

const HeaderContainer = styled.div`
  ${tw`
        flex justify-end
        flex-col md:flex-row  
        items-center md:items-end 
        my-5 md:mb-16 md:mt-0
        order-2 md:order-1
    `}
`;

const LogoLink = styled(Link)`
  @media (max-width: 768px) {
    ${tw`mx-auto`}
  }
`;

const HeaderLogo = styled.div`
  ${tw`
        flex flex-col items-start
        mx-auto md:mr-auto md:ml-0
        max-w-md
    `}

  > p {
    ${tw`
        mt-5 mb-3 md:mb-0
        text-center md:text-left
    `}
  }
`;

const SocialLink = styled.a`
  ${tw`
        flex items-center justify-center
        h-10 w-10
    `}
`;

const HeaderSocials = styled.div`
  > ul {
    ${tw`
        flex 
        list-none
    `}

    > li {
      ${tw`mr-1`}
    }
  }

  img,
  svg {
    ${tw`
        transition-all duration-100
        filter grayscale
        w-5 h-auto
        hover:grayscale-0
    `}
  }
`;

const BodyContainer = styled.nav`
  ${tw`order-1 md:order-2`}
`;

const SectionHeader = styled.div`
  ${tw`
        flex
        uppercase font-semibold
        cursor-default
    `}
`;

const SectionsList = styled.ul`
  ${tw`
        grid md:grid-cols-4 lg:grid-cols-6
        gap-x-10
        list-none
    `}
`;

const SectionListItem = styled.li`
  ${tw`
        py-5 md:py-0 md:mb-5
        border-0 border-t-2 md:border-0
    `}

  @media (max-width: 768px) {
    :last-of-type {
      ${tw`border-b-2`}
    }
  }
`;

const DropdownArrow = styled(DropdownChevron)`
  ${tw`
        inline-block md:hidden
        ml-auto w-5 h-auto
    `}
`;

const SectionLinks = styled.ul`
  ${tw`
        grid
        py-5 
        px-0
    `}

  > li {
    ${tw`mb-2`}
  }

  @media (max-width: 768px) {
    ${tw`
        py-0
        // transition-all duration-300
        origin-top transform scale-y-0 
        max-h-0
    `}

    ${({ open }) =>
      open &&
      tw`     
        transform scale-y-100
        max-h-full
        pt-5
        `}
  }
`;

const SectionLink = styled(Link)`
  :hover {
    color: darkorange;
  }
`;

const FooterContainer = styled.footer`
  ${tw`
        border-0 border-t-2
        order-3
        pt-3 mb-6
        flex justify-between
        gap-4
    `}
`;

const Footer = () => {
  const [mql, setMql] = useState();
  const [dropdownStates, setDropdownStates] = useState(
    new Array(FOOTER_DATA.length).fill(false)
  );

  useEffect(() => {
    setMql(window.matchMedia("(max-width: 768px)"));
  }, []);

  const toggleDropdown = (idx) => () => {
    if (mql.matches) {
      let newState = [...dropdownStates];
      newState[idx] = !newState[idx];
      setDropdownStates(newState);
    }
  };

  return (
    <Wrapper>
      <Content>
        <HeaderContainer>
          <HeaderLogo>
            <LogoLink to="/">
              <StaticImage
                src="../../static/img/lt3_logo_flat.png"
                alt="LessThan3 Logo"
                placeholder="tracedSVG"
                layout="fixed"
                height={35} //hard-setting the width causes minor cutoff at the end of logo
              />
            </LogoLink>
            <p>
              We build innovative software and technology products that push
              beyond the limits of technological uncertainty to{" "}
              <i>disrupt industries</i> and <i>delight customers</i>.
            </p>
          </HeaderLogo>
          <HeaderSocials>
            <ul>
              {SOCIALS_DATA.map(({ url, imgSrc, alt }) => (
                <li>
                  <SocialLink href={url} target="_blank">
                    <img src={imgSrc} alt={alt} />
                  </SocialLink>
                </li>
              ))}
            </ul>
          </HeaderSocials>
        </HeaderContainer>
        <BodyContainer>
          <SectionsList>
            {FOOTER_DATA.map(({ section, to, links }, sectionIdx) => (
              <SectionListItem key={sectionIdx}>
                <SectionHeader onClick={toggleDropdown(sectionIdx)}>
                  <Link to={to}>{section}</Link>
                  <DropdownArrow />
                </SectionHeader>
                <SectionLinks open={dropdownStates[sectionIdx]}>
                  {links.map(({ label, to }, linkIdx) => (
                    <li key={linkIdx}>
                      <SectionLink to={to}>{label}</SectionLink>
                    </li>
                  ))}
                </SectionLinks>
              </SectionListItem>
            ))}
          </SectionsList>
        </BodyContainer>
        <FooterContainer>
          <span>
            &copy; {new Date().getFullYear()} LT3 Advanced Technology Group
          </span>
          <span tw="text-right">
            <a href="tel:8005906138">(800) 590-6138</a>
          </span>
        </FooterContainer>
      </Content>
    </Wrapper>
  );
};

export default Footer;

const SOCIALS_DATA = [
  {
    url: "https://www.instagram.com/lt3atg/",
    imgSrc: "/img/svg/instagram-orange.svg",
    alt: "Instagram",
  },
  {
    url: "https://www.facebook.com/lt3atg/",
    imgSrc: "/img/svg/facebook-orange.svg",
    alt: "Facebook",
  },
  {
    url: "https://www.linkedin.com/company/lt3atg/",
    imgSrc: "/img/svg/linkedin-orange.svg",
    alt: "LinkedIn",
  },
  {
    url: "https://www.twitter.com/lt3atg/",
    imgSrc: "/img/svg/twitter-orange.svg",
    alt: "Twitter",
  },
];

const FOOTER_DATA = [
  {
    section: "Software Services",
    to: "/build",
    links: [
      {
        label: "App Development",
        to: "/build/app-development",
      },
      {
        label: "Software Dev",
        to: "/build/software-dev",
      },
      {
        label: "DevOps",
        to: "/build/devops",
      },
      {
        label: "Cloud Computing",
        to: "/build/cloud-computing",
      },
      {
        label: "Network & Security",
        to: "/build/network-and-security",
      },
      {
        label: "Consulting",
        to: "/build/consulting",
      },
    ],
  },
  {
    section: "ATG Services",
    to: "/build/advanced-technology-group",
    links: [
      {
        label: "Edge Computing",
        to: "/build/edge-computing",
      },
      {
        label: "Artificial Intelligence",
        to: "/build/artificial-intelligence",
      },
      {
        label: "Machine Learning",
        to: "/build/machine-learning",
      },
      {
        label: "Neural Networks",
        to: "/build/neural-networks",
      },
      {
        label: "Cryptography",
        to: "/build/cryptography",
      },
      {
        label: "Blockchain",
        to: "/build/blockchain",
      },
      {
        label: "Virtual Reality",
        to: "/build/virtual-reality",
      },
      {
        label: "Augmented Reality",
        to: "/build/augmented-reality",
      },
      {
        label: "Computer Vision",
        to: "/build/computer-vision",
      },
      {
        label: "Internet of Things",
        to: "/build/internet-of-things",
      },
    ],
  },
  {
    section: "Digital Media",
    to: "/sell/digital-media",
    links: [
      {
        label: "UI / UX",
        to: "/sell/ui-ux",
      },
      {
        label: "Brand Development",
        to: "/sell/brand-development",
      },
      {
        label: "Web Development",
        to: "/sell/web-development",
      },
      {
        label: "Graphics Design",
        to: "/sell/graphics-design",
      },
    ],
  },
  {
    section: "Digital Marketing",
    to: "/sell/digital-marketing",
    links: [
      {
        label: "Digital Advertising",
        to: "/sell/digital-advertising",
      },
      {
        label: "Ad Creation",
        to: "/sell/ad-creation",
      },
      {
        label: "SEO",
        to: "/sell/seo",
      },
      {
        label: "Social Media",
        to: "/sell/social-media",
      },
      {
        label: "Public Relations",
        to: "/sell/public-relations",
      },
    ],
  },
  {
    section: "Packages",
    links: [
      {
        label: "App Development",
        to: "/pricing/app-development",
      },
      {
        label: "Website Development",
        to: "/pricing/website-development",
      },
      {
        label: "Digital Marketing",
        to: "/pricing/digital-marketing",
      },
      {
        label: "Social Media Management",
        to: "/pricing/social-media-management",
      },
    ],
  },
  {
    section: "Consulting",
    links: [
      {
        label: "Container Deposit Systems",
        to: "/pricing/container-deposit-systems",
      },
      {
        label: "Extended Producer Responsibility",
        to: "/pricing/extended-producer-responsibility",
      },
      {
        label: "Software Consulting",
        to: "/pricing/software-consulting",
      },
    ],
  },
  {
    section: "Solutions",
    links: [
      {
        label: "Beverage Container Image Processing",
        to: "/pricing/beverage-container-image-processing",
      },
      {
        label: "Commodity Tracking & Collection",
        to: "/pricing/commodity-tracking",
      },
    ],
  },
  {
    section: "Integrations",
    links: [
      {
        label: "ChatGPT/LLM",
        to: "/pricing/chatgpt-integration",
      },
      {
        label: "Image Recognition",
        to: "/pricing/image-recognition-integration",
      },
    ],
  },
  {
    section: "Key Industries",
    to: "/key-industries",
    links: [
      {
        label: "Environmental",
        to: "/key-industries/environmental",
      },
      {
        label: "Oil & Gas",
        to: "/key-industries/oil-and-gas",
      },
      {
        label: "Healthcare",
        to: "/key-industries/healthcare",
      },
      {
        label: "Logistics",
        to: "/key-industries/logistics",
      },
      {
        label: "Start Ups",
        to: "/key-industries/start-ups",
      },
    ],
  },
  {
    section: "Projects",
    to: "/projects",
    links: [
      {
        label: "Express",
        to: "/projects/express",
      },
      {
        label: "Chain of Custody",
        to: "/projects/chain-of-custody",
      },
      {
        label: "SkipTheDepot",
        to: "/projects/skipthedepot",
      },
      {
        label: "Trotter & Morton",
        to: "/projects/trotter-and-morton",
      },
      {
        label: "Ventrek",
        to: "/projects/ventrek",
      },
      {
        label: "Ichor",
        to: "/projects/ichor",
      },
      {
        label: "ReCollect",
        to: "/projects/recollect",
      },
    ],
  },
  {
    section: "Community",
    links: [
      {
        label: "Media",
        to: "/media",
      },
      {
        label: "Awards",
        to: "/awards",
      },
      {
        label: "Blog",
        to: "/blog",
      },
      {
        label: "About LT3",
        to: "/about-lt3",
      },
    ],
  },
  {
    section: "Our Strengths",
    links: [
      {
        label: "Are You Mobile?",
        to: "/lt3-logistics-engine",
      },
      {
        label: "Need a Website?",
        to: "/sell/web-development",
      },
      {
        label: "Bottle Bill?",
        to: "/container-deposit-scheme",
      },
    ],
  },
];
